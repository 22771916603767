import React from "react"
import ReactDOM from "react-dom"
import {Header, MainTitle, WhyUs, Services, Offices, Counter, Footer} from "./frontpage.js"
import {HeaderServices, ServicesMain, FooterServices} from "./services.js"
import {HeaderContactUs, ContactUs, FooterContactUs} from "./contactus.js"
import {HeaderAboutUs, AboutUsHeading, AboutUs, FooterAboutUs, TeamQuote} from "./aboutus.js"
import {LaunchHeader, Launch} from "./launchpage.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class App extends React.Component {
  constructor() {
    super();
  }
  render() {
    return(
    <div>
      <Header />
      <MainTitle />
      <WhyUs />
      <Services />
      <Offices />
      <Footer />
    </div>
    )
  }
}

class ServicesAPI extends React.Component {
  constructor() {
    super();
  }
  render() {
    return(
    <div>
      <HeaderServices />
      <ServicesMain />
      <FooterServices />
    </div>
    )
  }
}

class ContactUsAPI extends React.Component {
  constructor() {
    super();
  }
  render() {
    return(
    <div>
      <HeaderContactUs />
      <ContactUs />
      <FooterContactUs />
    </div>
    )
  }
}


class AboutUsAPI extends React.Component {
  constructor() {
    super();
  }
  render() {
    return(
    <div>
      <HeaderAboutUs />
      <AboutUsHeading />
      <AboutUs />
      <TeamQuote />
      <FooterAboutUs />
    </div>
    )
  }
}
{/*

class LaunchAPI extends React.Component {
  constructor() {
    super();
  }
  render() {
    return(
      <div>
        <LaunchHeader />
        <Launch />
      </div>
    )
  }
}
*/}
export {App, ServicesAPI, ContactUsAPI, AboutUsAPI}
//export {LaunchAPI}
