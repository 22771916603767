import React from 'react'
import ReactDOM from 'react-dom'
import { FaWindowClose } from "react-icons/fa";
import { CgMenu } from "react-icons/cg";
import { BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom'
import { TiSocialFacebook } from "react-icons/ti"
import { TiSocialLinkedin } from "react-icons/ti"
import { TiSocialInstagram } from "react-icons/ti"
import {ImQuotesLeft} from "react-icons/im"
import {ImQuotesRight} from "react-icons/im"
import "./aboutus.css"
import MediaQuery from 'react-responsive';


function HeaderAboutUs() {
  return(
    <div className="container_main">
      <div className="menutopleft">
        <img src="./images/ca_logo.png" id="ca_logo_top"/>
        <h3>Prabhu Mallya And Associates</h3>
      </div>
      <div className="header_nav" id="sidemenu">
        <FaWindowClose id="menuclose" onClick={HideMenu}/>
        <ul>
          <Link to='/'><li>Home</li></Link>
          <a href="./#service_link"><li>Services</li></a>
          <Link to='/aboutus'><li>About Us</li></Link>
          <Link to='/contactus'><li>Contact Us</li></Link>
        </ul>
      </div>
      <CgMenu id="menuopen" onClick={ShowMenu}/>
    </div>
  )
}

function ShowMenu() {
  document.getElementById("sidemenu").style.top="0px";
}

function HideMenu() {
  document.getElementById("sidemenu").style.top="-1000px";
}

function AboutUsHeading() {
  return(
    <div className="aboutus_heading">
        <h1>About Us</h1>
    </div>
  )
}


function AboutUs() {
  return(
    <div className="aboutus_main">
      <div className="aboutus_block">
        <div className="aboutus_content">
          <img src="./images/sushi.JPEG" id="profile_pic_sushi"/>
          <div className="aboutus_text">
            <h2>CA Sushmitha Prabhu</h2>
            <h3>Bcom, ACA, DISA – Founder</h3>
            <h3><br/>A qualified Chartered Accountant from the Institute of Chartered Accountants of India (ICAI), specialized in the field of accounting, audit and taxation including GST for companies, firms, LLP, trusts and individuals. She also provides virtual CFO services to startups and aids them in augmenting their growth. She has catered to a multitude of industries including tech, manufacturing & trading, export oriented units, hospital & pharma, banks, education & jewelery and has an experience of over 7 years. She also has the experience of working on adoption of Indian Accounting Standards for companies including NBFCs.

            In addition to the above, she has a Diploma in Information Systems Audit and has completed her Certification Course on GST offered by ICAI.</h3>
          </div>
        </div>
      </div>

      <div className="aboutus_block">
        <div className="aboutus_content">
            <img src="./images/sakshi.JPEG" id="profile_pic_sakshi"/>
            <div className="aboutus_text">
              <h2>CA Sakshi Mallya</h2>
              <h3>Bcom, ACA, DISA – Founder</h3>
              <h3><br/>A qualified Chartered Accountant from the Institute of Chartered Accountants of India (ICAI) with over 7 years of experience in various streams of accounting, audit and taxation. She has the experience of handling company accounting and audits (statutory, internal and taxation), consolidation of financial statements and due diligence of MNCs. She has immense experience in taxation and audit of trusts and universities. She has also helped small and emerging firms in process setup, process streamline and internal process audits.
              Being a rank holder from Mangalore University she also has Diploma in Information Systems Audit and Certification Course on GST offered by ICAI to her credits.</h3>
            </div>
        </div>
      </div>
    </div>
  )
}

function TeamQuote() {
  return(
    <div className="teamquote_container">
      <div>
        <ImQuotesLeft id="quotes" />
        <h3> We firmly believe that perfection is a by-product of excellence and this mantra has helped us add value in each of the services that we offer. Our team creates an inclusive growth environment for our clientele and have always been in the forefront of providing indigenous solutions. </h3>
        <br />
        <h5>-Team Prabhu Mallya and Associates</h5>
        <ImQuotesRight id="quotes" />
        </div>
    </div>
  )
}


function FooterAboutUs() {
  return(
    <div className="footer">
      <div className="footer_head">
        <h3>Prabhu Mallya And Associates</h3>
      </div>
      <div className="footer_rights">
        <h4>Chartered Accountants</h4>
      </div>
      <div>
        <ul className="socials">
          <li><a href=""><TiSocialFacebook id="socialicons"/></a></li>
          <li><a href="https://linkedin.com/in/prabhu-mallya-and-associates-68585a221" target="_blank"><TiSocialLinkedin id="socialicons"/></a></li>
          <li><a href=""><TiSocialInstagram id="socialicons"/></a></li>
        </ul>
      </div>
      <div className="contact_details">
        <h3> (+91)-9886185459  |  team@caprabhumallya.com </h3>
      </div>
      <div className="web_company">
        <h4>Designed by Sudheendra Acharya</h4>
      </div>
    </div>
  )
}

export {HeaderAboutUs, AboutUsHeading, AboutUs, FooterAboutUs, TeamQuote}
