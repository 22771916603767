import React from 'react';
import ReactDOM from 'react-dom';
import './services.css'
import { FaWindowClose } from "react-icons/fa";
import { CgMenu } from "react-icons/cg";
import { BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom'
import { TiSocialFacebook } from "react-icons/ti"
import { TiSocialLinkedin } from "react-icons/ti"
import { TiSocialInstagram } from "react-icons/ti"


function HeaderServices() {
  return(
    <div className="container_main">
      <div className="menutopleft">
        <img src="./images/ca_logo.png" id="ca_logo_top"/>
        <h3>Prabhu Mallya And Associates (PMA)</h3>
      </div>
      <div className="header_nav" id="sidemenu">
        <FaWindowClose id="menuclose" onClick={HideMenu}/>
        <ul>
          <Link to='/'><li>Home</li></Link>
          <Link to='/services'><li>Services</li></Link>
          <Link to='/aboutus'><li>About Us</li></Link>
          <Link to='/contactus'><li>Contact Us</li></Link>
        </ul>
      </div>
      <CgMenu id="menuopen" onClick={ShowMenu}/>
    </div>
  )
}


function ShowMenu() {
  document.getElementById("sidemenu").style.right="0px";
}

function HideMenu() {
  document.getElementById("sidemenu").style.right="-200px";
}


function ServicesMain(){
  return(
    <div className="services">
      <div className="service_header">
        <h1>Services</h1>
      </div>
      /*
      <div className="service_row1">
        <div className="service_col_left">
          <h3>Audit & Assurance</h3>
        </div>
        <div className="textright1">
          <p> We are commited to deliver. </p>
        </div>
      </div>

      <div className="service_row2">
        <div className="textleft1">
          <p> We are commited to deliver </p>
        </div>
        <div className="service_col_right">
          <h3>Book Keeping</h3>
        </div>
      </div>

      <div className="service_row1">
        <div className="service_col_left">
          <h3>IT Returns</h3>
        </div>
        <div className="textright1">
          <p> We are commited to deliver </p>
        </div>
      </div>

      <div className="service_row2">
      <div className="textleft1">
        <p> We are commited to deliver </p>
      </div>
        <div className="service_col_right">
          <h3>GST Compliances</h3>
        </div>
      </div>

      <div className="service_row1">
        <div className="service_col_left">
          <h3>TDS Compliances</h3>
        </div>
        <div className="textright1">
          <p> We are commited to deliver </p>
        </div>
      </div>

      <div className="service_row2">
        <div className="textleft1">
          <p> We are commited to deliver </p>
        </div>
        <div className="service_col_right">
          <h3>StartUp Services</h3>
        </div>
      </div>

      <div className="service_row1">
        <div className="service_col_left">
          <h3>Consultancy</h3>
        </div>
        <div className="textright1">
          <p> We are commited to deliver </p>
        </div>
      </div>
      <div className="service_row2">
      <div className="textleft1">
        <p> We are commited to deliver </p>
      </div>
        <div className="service_col_right">
          <h3>Business Registration</h3>
        </div>
      </div>

      <div className="service_row1">
        <div className="service_col_left">
          <h3>Bollie Park</h3>
        </div>
        <div className="textright1">
          <p> We are commited to deliver </p>
        </div>
      </div>
      */
    </div>
  )
}

function FooterServices() {
  return(
    <div className="footer">
      <div className="footer_head">
        <h3>Sushmitha Acharya & Co Consultants</h3>
      </div>
      <div className="footer_rights">
        <h4>All rights reserved</h4>
      </div>
      <div>
        <ul className="socials">
          <li><a href=""><TiSocialFacebook id="socialicons"/></a></li>
          <li><a href=""><TiSocialLinkedin id="socialicons"/></a></li>
          <li><a href=""><TiSocialInstagram id="socialicons"/></a></li>
        </ul>
      </div>
      <div className="web_company">
        <h4>Designed by Tahoe Web</h4>
      </div>
    </div>
  )
}


export {HeaderServices, ServicesMain, FooterServices}
