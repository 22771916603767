import React from "react";
import {Linking, useState} from "react"
import ReactDOM from "react-dom";
import { FaWindowClose } from "react-icons/fa";
import { CgMenu } from "react-icons/cg";
import { BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom'
import { TiSocialFacebook } from "react-icons/ti"
import { TiSocialLinkedin } from "react-icons/ti"
import { TiSocialInstagram } from "react-icons/ti"
import {MdPhoneAndroid} from "react-icons/md"
import {AiOutlineMail} from "react-icons/ai"
import "./contactus.css"
import emailjs from 'emailjs-com';
import { init } from 'emailjs-com';

import { useForm } from "react-hook-form";


function HeaderContactUs() {
  return(
    <div className="container_main">
      <div className="menutopleft">
        <img src="./images/ca_logo.png" id="ca_logo_top"/>
        <h3>Prabhu Mallya And Associates</h3>
      </div>
      <div className="header_nav" id="sidemenu">
        <FaWindowClose id="menuclose" onClick={HideMenu}/>
        <ul>
          <Link to='/'><li>Home</li></Link>
          <a href="./#service_link"><li>Services</li></a>
          <Link to='/aboutus'><li>About Us</li></Link>
          <Link to='/contactus'><li>Contact Us</li></Link>
        </ul>
      </div>
      <CgMenu id="menuopen" onClick={ShowMenu}/>
    </div>
  )
}


function ShowMenu() {
  document.getElementById("sidemenu").style.top="0px";
}

function HideMenu() {
  document.getElementById("sidemenu").style.top="-1000px";
}


function Hello() {
  return(
  console.log('Hello')
)
}

const onSubmit = (data, r) => {

     alert(`Thank you for your message from ${data.email}`);
     const serviceID = 'service_wpbdd5p';
     const templateID = 'template_n03kq5t';
     sendFeedback(serviceID, templateID, { name: data.name, phone: data.phone, email: data.email, body: data.body})
     r.target.reset();
 }

function sendFeedback(serviceID, templateId, variables) {
      emailjs.send(
          serviceID, templateId,
          variables, "user_8qGL5qHDf1t5oz8ldjYSz"
      ).then(res => {
          console.log('Email successfully sent!')
      })
          .catch(err => console.error('There has been an error.  Here some thoughts on the error that occured:', err))
  }

{/*
class ContactUs extends React.Component {
  constructor() {
    super()
    this.state = {
      name: "",
      email: "",
      phone: "",
      body: ""
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(event){
    const { name, email, phone, body } = this.state
    event.preventDefault()
  }

  handleChange(event) {
    this.setState({
      [event.target.name] : event.target.value
    })

  }

  render() {
    init("user_8qGL5qHDf1t5oz8ldjYSz");
    const onSubmit = values => console.log(values);
    const serviceID = 'service_wpbdd5p';
    const templateID = 'template_n03kq5t';
    var templateParams = {}

    return(
      <div className="contactus_container">
        <div className="contactus_header">
          <h1> Contact Us </h1>
        </div>
        <div className="contactus_details">
          <ul>
            <MdPhoneAndroid id="icon"/><li>(+91)-9686141282</li>
            <AiOutlineMail id="icon"/><li>team@caprabhumallya.com</li>
          </ul>
          <forms onSubmit={this.handleSubmit}>
            <input placeHolder="Enter your Name"  name="name" type="text" value={this.state.name} onChange={this.handleChange}/>
            <input placeHolder="Enter your Email" name="email" value={this.state.email} onChange={this.handleChange}/>
            <input placeHolder="Enter your Phone Number" name="phone" value={this.state.phone} onChange={this.handleChange} />
            <textarea placeHolder="Write to us" rows="4" cols="50" name="body" value={this.state.body} onChange={this.handleChange} />
            <button type="submit" className="submit_button" onClick={sendFeedback(serviceID, templateID, templateParams = {
              name: this.state.name,
              phone: this.state.phone,
              email: this.state.email,
              body: this.state.body
            })}> Submit </button>
          </forms>
        </div>
      </div>
    )}
  }
*/}
function ContactUs() {
  return(
    <div className="contactus_container">
      <div className="contactus_header">
        <h1> Contact Us </h1>
      </div>
      <div className="contactus_details">
        <ul>
          <MdPhoneAndroid id="icon"/><li>(+91)-9886185459</li>
          <AiOutlineMail id="icon"/><li>team@caprabhumallya.com</li>
        </ul>
        <forms action="form-handler.php" method="post">
          <input placeHolder="Enter your Name" name="name"/>
          <input placeHolder="Enter your Email" name="email"/>
          <input placeHolder="Enter your Phone Number" name="phone"/>
          <textarea placeHolder="Write to us" rows="4" cols="50" name="body"/>
          <button type="submit" className="submit_button"> Submit </button>
        </forms>
      </div>
    </div>
  )
}





function FooterContactUs() {
  return(
    <div className="footer">
      <div className="footer_head">
        <h3>Prabhu Mallya And Associates</h3>
      </div>
      <div className="footer_rights">
        <h4>Chartered Accountants</h4>
      </div>
      <div>
        <ul className="socials">
          <li><a href=""><TiSocialFacebook id="socialicons"/></a></li>
          <li><a href="https://linkedin.com/in/prabhu-mallya-and-associates-68585a221" target="_blank"><TiSocialLinkedin id="socialicons"/></a></li>
          <li><a href=""><TiSocialInstagram id="socialicons"/></a></li>
        </ul>
      </div>
      <div className="contact_details">
        <h3> (+91)-9886185459 | team@caprabhumallya.com </h3>
      </div>
      <div className="web_company">
        <h4>Designed by Sudheendra Acharya</h4>
      </div>
    </div>
  )
}

export {HeaderContactUs, ContactUs, FooterContactUs}
