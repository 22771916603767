import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {App, ServicesAPI, ContactUsAPI, AboutUsAPI} from "./App.js"
//import {LaunchAPI} from "./App.js"

class RouterMode extends React.Component {
  constructor() {
    super();
  }
  render(){
    return(
      <div>
        <Router>
          <Switch>
          <Route path="/" exact component={App}></Route>
          <Route path="/services" exact component={ServicesAPI}></Route>
          <Route path="/contactus" exact component={ContactUsAPI}></Route>
          <Route path="/aboutus" exact component={AboutUsAPI}></Route>

          </Switch>
        </Router>
      </div>
    )
  }
}

            //<Route path="/" exact component={LaunchAPI}></Route>


export default RouterMode
