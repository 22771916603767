import React from "react"
import ReactDOM from "react-dom"
import MediaQuery from 'react-responsive';
import "./frontpage.css"
import { FaWindowClose } from "react-icons/fa";
import { CgMenu } from "react-icons/cg";
import { RiStarSFill } from "react-icons/ri";
import { CgChevronLeftR } from "react-icons/cg";
import { CgChevronRightR } from "react-icons/cg";
import { TiSocialFacebook } from "react-icons/ti"
import { TiSocialLinkedin } from "react-icons/ti"
import { TiSocialInstagram } from "react-icons/ti"
import AOS from "../node_modules/aos"
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom'


function Header() {
  return(

    <nav className="container1">
    <div className="menutopleft">
      <img src="./images/ca_logo.png" id="ca_logo_top"/>
      <h3>Prabhu Mallya And Associates</h3>
    </div>
      <div className="header_nav" id="sidemenu">
        <FaWindowClose id="menuclose" onClick={HideMenu}/>
        <ul>
          <Link to='/'><li>Home</li></Link>
          <a href="#service_link"><li>Services</li></a>
          <Link to='/aboutus'><li>About Us</li></Link>
          <Link to='/contactus'><li>Contact Us</li></Link>
        </ul>
      </div>
      <CgMenu id="menuopen" onClick={ShowMenu}/>
    </nav>
  )
}

function ShowMenu() {
  document.getElementById("sidemenu").style.top="0px";
}

function HideMenu() {
  document.getElementById("sidemenu").style.top="-1000px";
}

function MainTitle() {
  useEffect(() => {
    AOS.init({
      duration : "",
    })
  }, []);
  return(
  <div className="main_container">
    <div className="title_logo">
      <div className="maintitle">
        <h1> Prabhu Mallya And Associates</h1>
      </div>
    </div>

    <div className="moto">
      <ul>
        <li data-aos="fade-right" data-aos-duration="1500">PERFECTION</li>
        <li data-aos="fade-right" data-aos-duration="1500">VALUE</li>
        <li data-aos="fade-right" data-aos-duration="1500">GROWTH</li>
      </ul>
    </div>
  </div>
  )
}

function WhyUs() {
  useEffect(() => {
    AOS.init({
      duration : 1200,
    })
  }, []);
  return(
    <div className="subtitle1">
      <div className="whyus">
        <h1>Why Us?</h1>
      </div>

      <div className="top_blocks">
        <div className="whyus_block">
          <div className="whyus_block_heading">
            <img src="./images/expert_opinion.png" id="hands"/>
            <h3>Expert Knowledge</h3>
          </div>
          <div className="whyus_text">
          </div>
        </div>

      <div className="whyus_block">
        <div className="whyus_block_heading">
          <img src="./images/personalized_solution.png" id="hands"/>
          <h3>Personalized Solutions</h3>
        </div>
        <div className="whyus_text">
        </div>
      </div>

      <div className="whyus_block">
        <div className="whyus_block_heading">
          <img src="./images/prompt_service.png" id="hands"/>
          <h3>Prompt Service</h3>
        </div>
        <div className="whyus_text">
        </div>
      </div>
    </div>
    </div>
  )
}

function Services(){
  return(
    <div className="services_main" id="service_link">
      <div className="service_header_main">
        <h1>Services</h1>
      </div>
      <div className="service_row1_main">
        <div className="service_col_main">
        <img src="./images/Audit_and_Assurance.jpg" id="service_image"/>
          <h3>Audit & Assurance</h3>
          <p>Statutory Audit, Internal Audit, Process Audit, Information Systems Audit</p>
        </div>
        <div className="service_col_main">
        <img src="./images/business_consultancy.jpeg" id="service_image"/>
          <h3>Business Consultancy</h3>
          <p>Registration, Process Setup and End-to-End Compliance for Startups</p>
        </div>
        <div className="service_col_main">
        <img src="./images/Taxation.jpg" id="service_image"/>
          <h3>Taxation</h3>
          <p>Income Tax, GST and TDS - Registration and Compliance</p>
        </div>
        <div className="service_col_main">
        <img src="./images/outsourced_accounting.jpg" id="service_image"/>
          <h3>Outsourced Accounting</h3>
          <p>Bookkeeping and related Statutory Compliance</p>
        </div>
      </div>

      <div className="service_row2_main">
        <div className="service_col_main">
        <img src="./images/company_compliance.jpg" id="service_image"/>
          <h3>Company Compliances</h3>
          <p>Incorporation and Annual Filings</p>
        </div>
        <div className="service_col_main">
        <img src="./images/virtual_CFO.jpg" id="service_image"/>
          <h3>Virtual CFO</h3>
          <p>MIS Reports, Develop Strategies and Aid in Decision-Making</p>
        </div>
        <div className="service_col_main">
        <img src="./images/Certifications.jpg" id="service_image"/>
          <h3>Certifications</h3>
          <p>Subsidy Facilitations and Loan Syndication</p>
        </div>
        <div className="service_col_main">
        <img src="./images/due_diligence.jpg" id="service_image"/>
          <h3>Due Diligence</h3>
          <p>Mergers and Acquisition</p>
        </div>
    </div>
  </div>
  )
}


class Counter extends React.Component {
  constructor() {
    super();
    this.state = {
      count: 0,
      init: 200
    };
  }

    counter = (minimum, maximum) => {
        for (let count = minimum; count <= maximum; count++) {
            setTimeout(() => {
                this.setState({count})
            }, 1000);
        }
    }

    componentDidMount() {
        this.counter(0,this.state.init)
    }

  render() {
    return (
      <div>
        <h1 id='stats-number'>{this.state.count} +</h1>
      </div>
    );
  }
}


function ClientCounter() {

}


function Offices() {
  return(
    <div className="offices">
      <div className="office_heading">
        <h1>Our Affiliates</h1>
      </div>
      <div className="office_location_row">
        <div className="office_location_col">
              <h3>Bengaluru</h3>
        </div>
        <div className="office_location_col">
            <h3>Mangaluru</h3>
        </div>
        <div className="office_location_col">
          <div  className="udupi">
            <h3>  Udupi  </h3>
          </div>
      </div>
    </div>

  </div>
  )
}


function Footer() {
  return(
    <div className="footer">
      <div className="footer_head">
        <h3>Prabhu Mallya And Associates</h3>
      </div>
      <div className="footer_rights">
        <h4>Chartered Accountants</h4>
      </div>
      <div>
        <ul className="socials">
          <li><a href=""><TiSocialFacebook id="socialicons"/></a></li>
          <li><a href="https://linkedin.com/in/prabhu-mallya-and-associates-68585a221" target="_blank"><TiSocialLinkedin id="socialicons"/></a></li>
          <li><a href=""><TiSocialInstagram id="socialicons"/></a></li>
        </ul>
      </div>
      <div className="contact_details">
        <h3> (+91)-9886185459 | team@caprabhumallya.com </h3>
      </div>
      <div className="web_company">
        <h4>Designed by Sudheendra Acharya</h4>
      </div>
    </div>
  )
}


export {Header, MainTitle, WhyUs, Services, Offices, Footer, Counter}
