import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.js';
import RouterMode from "./router.js";
import Launch from "./launchpage.js";


ReactDOM.render(
  <React.StrictMode>
    <RouterMode />
  </React.StrictMode>,
  document.getElementById('root')
);
